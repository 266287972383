export const rules = {
  times: [{ required: true, message: '此为必填项', trigger: 'blur' }],
  publishType: [{ required: true, message: '此为必填项', trigger: 'change' }],
  startTime: [{ required: true, message: '此为必填项', trigger: 'change' }],
  endTime: [{ required: true, message: '此为必填项', trigger: 'change' }],
  recursiveDeviceStr: [{ required: true, message: '此为必填项', trigger: 'change' }],
  recursiveDevice: [{ required: true, message: '此为必填项', trigger: 'change' }],
  activeDate: [{ required: true, message: '此为必填项', trigger: 'change' }],
  loopModeType: [{ required: true, message: '此为必填项', trigger: 'change' }],
  periodActivityTitle: [{ required: true, message: '此为必填项', trigger: 'blur' }],
  activityTitle: [{ required: true, message: '此为必填项', trigger: 'blur' }],
  activityImage: [{ required: true, message: '此为必填项', trigger: ['change', 'click'] }],
  activityTypeId: [{ required: true, message: '此为必填项', trigger: 'change' }],
  address: [{ required: true, message: '此为必填项', trigger: 'change' }],
  detailAddress: [{ required: true, message: '此为必填项', trigger: 'change' }],
  activityDetail: [{ required: true, message: '此为必填项', trigger: 'change' }],
  projectIdList: [{ required: true, message: '此为必填项', trigger: 'change' }],
  userRestriction: [{ required: true, message: '此为必填项', trigger: 'change' }],
  enterpriseTypeIdIdList: [{ required: true, message: '此为必填项', trigger: 'change' }],
  fileL: [{ required: true, message: '此为必填项', trigger: 'change' }],
  isApply: [{ required: true, message: '此为必填项', trigger: 'change' }],
  participationWay: [{ required: true, message: '此为必填项', trigger: 'change' }],
  isNotification: [{ required: true, message: '此为必填项', trigger: 'change' }],
  notificationType: [{ required: true, message: '此为必填项', trigger: 'change' }],
  messagePushTime: [{ required: true, message: '此为必填项', trigger: 'change' }],
  isShare: [{ required: true, message: '此为必填项', trigger: 'change' }],
  isQuestionnaire: [{ required: true, message: '此为必填项', trigger: 'change' }],
  viewDetailAddress: [{ required: true, message: '此为必填项', trigger: 'change' }],
  questionnaireName: [{ required: true, message: '此为必填项', trigger: ['change', 'click'] }],
  fileName: [{ required: true, message: '此为必填项', trigger: 'change' }],
  isShowNeedNotRegister: [{ required: true, message: '此为必填项', trigger: 'change' }],
  personnelCeilingType: [{ required: true, message: '此为必填项', trigger: 'change' }],
  activityPeriod: [{ required: true, message: '此为必填项', trigger: 'change' }],
};
